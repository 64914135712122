import { getFormProps, getInputProps, useForm } from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import * as E from '@react-email/components'
import {
	json,
	redirect,
	type DataFunctionArgs,
	type MetaFunction,
} from '@remix-run/node'
import {
	Form,
	useActionData,
	useLoaderData,
	useSearchParams,
} from '@remix-run/react'
import { HoneypotInputs } from 'remix-utils/honeypot/react'
import { safeRedirect } from 'remix-utils/safe-redirect'
import { z } from 'zod'
import { CheckboxField, ErrorList, Field } from '#app/components/forms.tsx'
import { Spacer } from '#app/components/spacer.tsx'
import { StatusButton } from '#app/components/ui/status-button.tsx'
import { getUserId, requireAnonymous, sessionKey, signup } from '#app/utils/auth.server.ts'
import { prisma } from '#app/utils/db.server.ts'
import { checkHoneypot } from '#app/utils/honeypot.server.ts'
import { invariant, useIsPending } from '#app/utils/misc.tsx'
import { authSessionStorage } from '#app/utils/session.server.ts'
import { redirectWithToast } from '#app/utils/toast.server.ts'
import {
	NameSchema,
	PasswordAndConfirmPasswordSchema,
	UsernameSchema,
} from '#app/utils/user-validation.ts'
import { verifySessionStorage } from '#app/utils/verification.server.ts'
import { type VerifyFunctionArgs } from './verify.tsx'
import { useEffect } from 'react'
import { toast } from 'sonner'
import { logger } from '#app/utils/logger.ts'
import { sendEmail } from '#app/utils/email.server.ts'

const onboardingEmailSessionKey = 'onboardingEmail'

const SignupFormSchema = z
	.object({
		name: NameSchema,
		username: UsernameSchema,
		agreeToTermsOfServiceAndPrivacyPolicy: z.boolean({
			required_error:
				'You must agree to the terms of service and privacy policy',
		}),
		remember: z.boolean().optional(),
		redirectTo: z.string().optional(),
	})
	.and(PasswordAndConfirmPasswordSchema)

async function requireOnboardingEmail(request: Request) {
	await requireAnonymous(request)
	const verifySession = await verifySessionStorage.getSession(
		request.headers.get('cookie'),
	)
	const email = verifySession.get(onboardingEmailSessionKey)
	if (typeof email !== 'string' || !email) {
		throw redirect('/signup')
	}
	return email
}
export async function loader({ request }: DataFunctionArgs) {
	const email = await requireOnboardingEmail(request)

	// If there's already a user, go to the homepage
	let userId = await getUserId(request, true);
	if (userId) {
		return redirect('/')
	}


	return json({ email })
}

export async function action({ request }: DataFunctionArgs) {
	const email = await requireOnboardingEmail(request)
	const formData = await request.formData();
	checkHoneypot(formData)

	let submission;
	try {
		submission = await parseWithZod(formData, {
			schema: intent =>
				SignupFormSchema.superRefine(async (data, ctx) => {
					const existingUser = await prisma.user.findUnique({
						where: { username: data.username },
						select: { id: true },
					})
					if (existingUser) {
						ctx.addIssue({
							// path: ['username'],
							code: z.ZodIssueCode.custom,
							message: 'A user already exists with this username',
						})
						return
					}
				}).transform(async data => {
					if (intent !== null) return { ...data, session: null }
	
					const session = await signup({ ...data, email })
					return { ...data, session }
				}),
			async: true,
		})
	} catch (err: any) {
		logger.error({ err }, 'Error while processing signup form.')

		let title = 'We ran into an error.'
		let message = 'An error occurred while processing your request. Please try again later.'
		let status = 500;
		if (err?.name === 'ValidationError') {
			title = err.userTitle;
			message = err.userMessage;
			status = err.status ? err.status : status;
		}

		return json({error: {
			title: title,
			description: message
		}}, {status: status})
	}

	if (submission.status !== 'success' || !submission.value.session) {
		return json(
			{ result: submission.reply() },
			{ status: submission.status === 'error' ? 400 : 200 },
		)
	}

	const { session, remember, redirectTo } = submission.value

	const authSession = await authSessionStorage.getSession(
		request.headers.get('cookie'),
	)
	authSession.set(sessionKey, session.id)
	const verifySession = await verifySessionStorage.getSession()
	const headers = new Headers()
	headers.append(
		'set-cookie',
		await authSessionStorage.commitSession(authSession, {
			expires: remember ? session.expirationDate : undefined,
		}),
	)
	headers.append(
		'set-cookie',
		await verifySessionStorage.destroySession(verifySession),
	)

	const response = await sendEmail({
		to: 'help@savvyiq.ai',
		subject: `New User Signup`,
		react: <NewUserNotify email={email} />,
	})

	return redirectWithToast('/activation',
		{ title: 'Welcome', description: 'Thanks for signing up!' },
		{ headers },
	)

	// return redirectWithToast(
	// 	safeRedirect(redirectTo),
	// 	{ title: 'Welcome', description: 'Thanks for signing up!' },
	// 	{ headers },
	// )
}

export function NewUserNotify({
	email,
}: {
	email: string
}) {
	return (
		<E.Html lang="en" dir="ltr">
			<E.Container>
				<h1>
					<E.Text>New User Signup</E.Text>
				</h1>
				<p>
					<E.Text>
						{email} has signed up for a new account.
					</E.Text>
				</p>
			</E.Container>
		</E.Html>
	)
}

export async function handleVerification({ submission }: VerifyFunctionArgs) {
	invariant(
		submission.status === 'success',
		'Submission should be successful by now',
	)
	const verifySession = await verifySessionStorage.getSession()
	verifySession.set(onboardingEmailSessionKey, submission.value.target)
	return redirect('/onboarding', {
		headers: {
			'set-cookie': await verifySessionStorage.commitSession(verifySession),
		},
	})
}

export const meta: MetaFunction = () => {
	return [{ title: 'Onboarding' }]
}

export default function SignupRoute() {
	const data = useLoaderData<typeof loader>()
	const actionData = useActionData<typeof action>()
	const isPending = useIsPending()
	const redirectTo = '/'

	const [form, fields] = useForm({
		id: 'onboarding-form',
		constraint: getZodConstraint(SignupFormSchema),
		defaultValue: { redirectTo },
		lastResult: actionData?.result,
		onValidate({ formData }) {
			return parseWithZod(formData, { schema: SignupFormSchema })
		},
		shouldRevalidate: 'onBlur',
	})

	// Close the dialog when the fetcher has completed the delete operation successfully
	useEffect(() => {
		if (actionData?.error) {
			// Assuming fetcher.data has some confirmation of deletion
			const err = actionData.error;
			toast.error(err.title, {
				description: err.description,
			});
		}
	}, [actionData]);

	return (
		<div className="container flex min-h-full flex-col justify-center pb-32 pt-20">
			<div className="mx-auto w-full max-w-lg">
				<div className="flex flex-col gap-3 text-center">
					<h1 className="text-h1">Create Your Account</h1>
				</div>
				<Spacer size="xs" />
				<Form
					method="POST"
					className="mx-auto min-w-full max-w-sm sm:min-w-[368px]"
					{...getFormProps(form)}
				>
					<HoneypotInputs />
					<Field className='hidden'
						labelProps={{ htmlFor: fields.username.id, children: 'Username' }}
						inputProps={{
							...getInputProps(fields.username, {
								type: 'text',
							}),
							autoComplete: 'username',
							className: 'lowercase',
							value: data.email
						}}
						errors={fields.username.errors}
					/>
					<Field
						labelProps={{ htmlFor: fields.name.id, children: 'Name' }}
						inputProps={{
							...getInputProps(fields.name, { type: 'text' }),
							autoComplete: 'name',
						}}
						errors={fields.name.errors}
					/>
					<Field
						labelProps={{ htmlFor: fields.password.id, children: 'Password' }}
						inputProps={{
							...getInputProps(fields.password, { type: 'password' }),
							autoComplete: 'new-password',
						}}
						errors={fields.password.errors}
					/>

					<Field
						labelProps={{
							htmlFor: fields.confirmPassword.id,
							children: 'Confirm Password',
						}}
						inputProps={{
							...getInputProps(fields.confirmPassword, { type: 'password' }),
							autoComplete: 'new-password',
						}}
						errors={fields.confirmPassword.errors}
					/>

					<CheckboxField
						labelProps={{
							htmlFor: fields.agreeToTermsOfServiceAndPrivacyPolicy.id,
							children:
								(<div>
									I agree to our <a href="/tos" target='_blank' className='font-bold'>Terms of Service</a> and <a href="/privacy" target='_blank' className='font-bold'>Privacy Policy</a>.
								</div>),
						}}
						buttonProps={getInputProps(
							fields.agreeToTermsOfServiceAndPrivacyPolicy,
							{ type: 'checkbox' },
						)}
						errors={fields.agreeToTermsOfServiceAndPrivacyPolicy.errors}
					/>
					{/* <CheckboxField
						labelProps={{
							htmlFor: fields.remember.id,
							children: 'Remember me',
						}}
						buttonProps={getInputProps(fields.remember, { type: 'checkbox' })}
						errors={fields.remember.errors}
					/> */}

					<input {...getInputProps(fields.redirectTo, { type: 'hidden' })} />
					<ErrorList errors={form.errors} id={form.errorId} />

					<div className="flex items-center justify-between gap-6">
						<StatusButton
							className="w-full"
							status={isPending ? 'pending' : form?.status ?? 'idle'}
							type="submit"
							disabled={isPending}
						>
							Create an account
						</StatusButton>
					</div>
				</Form>
			</div>
		</div>
	)
}
